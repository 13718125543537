<template>
  <div class="detail-content">
    <TheNav />

    <div class="detail-body">
      <div class="alert-body" v-if="article.videoInfo == 1">
        <div class="alert">
          <p>抱歉哦，本课内容待补充，请先去看其他课程吧！</p>
        </div>
      </div>
      <div class="up" v-else>
        <div class="left">
          <div class="player">
            <iframe
              v-if="article.source == 'xigua'"
              width="832"
              height="468"
              frameborder="0"
              allowfullscreen="true"
              framespacing="0"
              scrolling="no"
              border="0"
              :src="videoSelected"
            >
            </iframe>

            <iframe
              v-else-if="article.source == 'bi'"
              width="832"
              height="532"
              frameborder="0"
              framespacing="0"
              scrolling="no"
              border="0"
              high_quality="1"
              allowfullscreen="true"
              :src="videoSelected"
            >
            </iframe>

            <div class="playerDown">
              <p class="episodeTitle">{{ currentTitle }}</p>
              <a
                href="https://support.qq.com/products/302506/"
                target="_blank"
                class="feedback"
                >视频不显示、信息有误、缺素材等，点我反馈</a
              >
            </div>
          </div>
          <div class="channels">
            <p class="channelsTitle">你也可以去这些平台观看我们的课程</p>
            <a
              href="https://space.bilibili.com/20503549"
              target="_blank"
              class="bilibili"
              >能发弹幕的B站</a
            >
            <a
              href="https://www.youtube.com/c/doyoudoteam"
              target="_blank"
              class="youtube"
              >适合国外同学的油管</a
            >
            <a href="http://i.youku.com/doyoudo" target="_blank" class="youku"
              >历史悠久的优酷</a
            >
          </div>
        </div>
        <div class="right">
          <div class="class-info">
            <span class="category">{{ article.category }}</span>
            <span class="level">{{ article.level }}</span>
            <h2 class="class-title">{{ article.ArticleTitle }}</h2>
            <p class="class-author">
              <span>本课作者：</span>
              <span>{{ article.author }}</span>
            </p>
          </div>
          <div class="attachment">
            <p class="attachment-title">本课素材</p>
            <a
              class="download"
              v-if="article.attachment != '0' && article.attachment"
              :href="article.attachment"
              target="_blank"
              >下载素材</a
            >
            <span v-else class="noDownload">这个课程没有素材</span>
          </div>
          <div class="playlist">
            <p class="list-title">播放列表</p>
            <ul class="unorder-list">
              <li v-for="item in article.videoInfo" :key="item">
                <span @click="switchEpisode(item.episodeNumber, article)">
                  <span>{{ item.episodeTitle }}</span>
                </span>
              </li>
            </ul>
          </div>
          <div class="ad" v-if="true">
            <a
              href="https://www.bilibili.com/cheese/play/ep1183"
              target="_blank"
              class="small-banner"
            >
              <img
                src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l0midk0i0bz1.jpg"
                alt="周边广告图"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="down"></div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import { ref, onMounted, update, onUpdated } from "vue";
import db from "../assets/db.json";
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";

export default {
  props: ["id"],
  setup(props) {
    components: {
      TheFooter, TheNav;
    }
    let article = db.filter((item) => item.id == props.id)[0];

    const videoDefault = article.videoInfo[0].episodeLink;
    let videoSelected = ref(videoDefault);

    const currentTitleDefault = article.videoInfo[0].episodeTitle;
    let currentTitle = ref(currentTitleDefault);

    onMounted(() => {
      console.log("article ", article);
      if (article.source === "bi") {
        videoSelected.value = article.videoInfo[0].episodeLink + "&page=1";
      } else {
        videoSelected.value =
          "https://www.ixigua.com/iframe/" +
          article.videoInfo[0].episodeLink +
          "?autoplay=0&startTime=0";
      }
    });

    /*    function switchEpisode(x, article) {
      console.log(article);
      videoSelected.value =
        "https://www.ixigua.com/iframe/" + article.videoInfo[x - 1].episodeLink;

      currentTitle.value = article.videoInfo[x - 1].episodeTitle;
    } */
    function switchEpisode(x, article) {
      switch (article.source) {
        case "bi":
          videoSelected.value =
            article.videoInfo[x - 1].episodeLink + "&page=" + x;
          break;
        default:
          videoSelected.value =
            "https://www.ixigua.com/iframe/" +
            article.videoInfo[x - 1].episodeLink;
      }

      currentTitle.value = article.videoInfo[x - 1].episodeTitle;
    }

    return {
      article,
      switchEpisode,
      videoSelected,
      currentTitle,
      TheFooter,
      TheNav,
    };
  },
};
</script>

<style scoped>
.alert {
  width: 90%;
  height: 110px;
  padding: 20px 30px;
  background: #fef6ed;
  border: #f5cd84 solid 1px;
  border-radius: 9px;
  margin: 0 auto;
}
.alert p {
  font-size: 1em;
  color: #46330a;
}
.alert-body {
  width: 100%;
  height: 700px;
  background: #f5f6f9f8;
}

.detail-content {
  background: #f5f6f9f8;
}
.detail-body {
  width: 1240px;
  margin: 0 auto;
}

.up {
  display: flex;
  justify-content: space-between;
}

.player,
.channels,
.class-info,
.attachment,
.playlist,
.ad {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background: #fff;
  margin-bottom: 25px;
}

.channels {
  margin-bottom: 110px;
}

.left .playerDown {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.left .feedback {
  font-size: 12px;
  color: #a7acb6;
}
.left .feedback:hover {
  color: #8ca80b;
}

.right .category {
  background: #f7ffd1;
  color: #8ca80b;
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  font-size: 12px;
}
.right .level {
  color: #4e4e4e;
  padding: 5px 8px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  background: #efeff0;
}

.left .episodeTitle {
  font-size: 18px;
  flex-basis: 100%;
}

.class-info,
.playlist,
.attachment,
.ad {
  padding: 20px;
}

.class-info .class-title {
  font-size: 20px;
  font-weight: bold;
  color: #2a301b;
  margin: 20px 0;
}

.right .class-info .class-author span {
  color: #686f7c;
  font-size: 14px;
}

.right .attachment .download {
  display: block;
  background: #a2c217;
  text-align: center;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  color: #fff;
}
.right .attachment .download:hover {
  background: #95b312;
}
.right .attachment .noDownload {
  font-size: 14px;
}

.unorder-list {
  overflow-y: scroll;
  max-height: 300px;
}

.list-title,
.attachment-title,
.channelsTitle {
  margin-bottom: 20px;
  font-weight: bold;
  color: #80aa15;
}
.unorder-list li {
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: lighter;
}
.unorder-list li span:hover {
  /* font-weight: bold; */
  color: #80aa15;
}
.unorder-list li:focus {
  font-weight: bold;
  color: red;
}

.ad img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.player,
.channels {
  width: 870px;
  padding: 20px;
}
.up .right {
  width: 325px;
}

.channels a {
  display: inline-block;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
  font-size: 14px;
}

.channels .bilibili {
  color: #73c9e5;
  border: 1px solid #73c9e5;
}
.channels .youtube {
  color: #e22020;
  border: 1px solid #e22020;
}
.channels .youku {
  color: #02d3ff;
  border: 1px solid #02d3ff;
}

.channels .bilibili:hover {
  color: #fff;
  border: 1px solid #73c9e5;
  background: #73c9e5;
}
.channels .youtube:hover {
  color: #fff;
  border: 1px solid #e22020;
  background: #e22020;
}
.channels .youku:hover {
  color: #fff;
  border: 1px solid #02d3ff;
  background: #02d3ff;
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .detail-body {
    width: 100%;
  }
  .up {
    width: 90%;
    margin: 20px auto;
  }
  .player,
  .channels {
    width: 100%;
    padding: 20px;
  }

  .right {
    width: 100%;
  }
  .left {
    width: 100%;
    margin-right: 3%;
  }
  .player iframe {
    width: 100%;
    height: 300px;
  }
  .channels a {
    width: 60%;
    font-size: 0.8em;
    margin-top: 15px;
  }

  .class-info .class-title {
    font-size: 1.1em;
  }
}
@media screen and (max-width: 470px) {
  .detail-body {
    width: 100%;
  }
  .up {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
  }
  .player,
  .channels {
    width: 100%;
    padding: 0;
  }

  .playerDown {
    padding: 0px 15px 20px 15px;
  }

  .up .right,
  .channels {
    width: 95%;
    margin: 0 auto;
  }

  .channels {
    padding: 20px;
  }
  .player iframe {
    width: 100%;
    height: 250px;
  }
  .channels a {
    width: 100%;
    font-size: 0.8em;
    margin-top: 15px;
  }

  .class-info .class-title {
    font-size: 1.1em;
  }

  .class-info {
    margin-top: 30px;
  }
}
</style>















